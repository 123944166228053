import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import {Link, useNavigate} from "react-router-dom";
import {useAuthStore} from "../../store/user-store";

export default function PayFreeProduct({product}) {
    const redirect = useNavigate()
    const authStore = useAuthStore()
    const [qty, setQty] = useState(product.min_qty)
    const [player_id, setPlayerId] = useState('')
    const [player_name, setPlayerName] = useState('')
    const [note, setNote] = useState('')
    const [totalPrice, setTotalPrice] = useState(product.min_qty * product.unit_price)
    const [totalPriceSyr, setTotalPriceSyr] = useState((product.min_qty * product.unit_price) * product.usd_price)
    const [errors, setErrors] = useState({qty: ''})
    const [loading, setLoading] = useState(false)
    const [loadingName, setLoadingName] = useState(false)
    const onSubmit = async () => {
        setErrors({})
        if (qty > product.max_qty || qty < product.min_qty) {
            setErrors({...errors, qty: `يرجى تحديد الكمية بين ${product.min_qty} -> ${product.max_qty}`})
            return;
        }
        if (player_id.length === 0) {
            setErrors({...errors, player_id: `يرجى إدخال رقم اللاعب`})
            return;
        }
        setLoading(true)
        try {
            const data = new FormData()
            data.append('player_id', player_id)
            data.append('player_name', player_name)
            data.append('product_id', product.id)
            data.append('qty', qty)
            data.append('note', note)
            const response = await baseApi.post('/orders', data)
            if (response.data?.user) {
                authStore.setUser(response.data?.user)
                localStorage.setItem('user', JSON.stringify(response.data?.user))

            }
            redirect('/orders')
        } catch (e) {
            setErrors({...errors, msg: e.response.data?.msg})
        }
        setLoading(false)

    }
    const checkPlayer = async () => {
        setLoadingName(true)
        const name = await authStore.getPlayerName(product.id, player_id);
        setPlayerName(name)
        setLoadingName(false)
    }

    useEffect(() => {
        setTotalPrice(product.unit_price * qty);


        if (qty > product.max_qty || qty < product.min_qty) {
            setErrors({...errors, qty: `يرجى تحديد الكمية بين ${product.min_qty} -> ${product.max_qty}`})
        } else {
            setErrors({...errors, qty: ''})
        }

    }, [qty])

    useEffect(()=>{
        setTotalPriceSyr(totalPrice * product.usd_price );
    },[totalPrice])
    return (
        <div className="col-md-8 ">
            <div className="form mx-3 my-2">
                <p className={'text-orange'}><i className="fas fa-caret-left text-orange fa-2x"/> {product.name}</p>

                <ul>
                    {Object.entries(errors).map(([key, value], i) => {
                        if (value) {
                            return <li key={i} className={'text-danger'}>
                                {value}
                            </li>
                        }
                    })}
                </ul>
                <div className=" px-2">
                    <div className="d-flex gap-2 align-items-center">
                        <input type="text" onChange={(e) => setPlayerId(e.target.value)}
                               placeholder={product.type === 'id' ? 'ID الحساب' : product.type === 'account' ? 'اسم المستخدم /  البريد الإلكتروني' : 'رقم الهاتف/رابط الصفحة'}
                               className={'input-form my-2'}/>
                        {authStore.checkPlayer && product.is_check ?
                            <span className={`checkName ${loadingName?'loading':''}`} onClick={checkPlayer}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg>
                        </span>
                            : null}
                    </div>

                    {authStore.checkPlayer && product.is_check?
                        <>
                            {player_name?.length==0?<span className={'d-block text-orange small'}>أضغط على السهم لفحص الاسم</span>:<span className={'text-orange small d-block'}>{player_name}</span>}
                        </>
                        : <input type="text" value={player_name} onChange={(e) => setPlayerName(e.target.value)}
                                 placeholder={product.type === 'id' ? 'اسم الحساب' : product.type === 'account' ? 'كلمة المرور' : 'اسم الحساب'}
                                 className={'input-form my-2'}/>
                    }



                    <div className="d-flex align-items-center">
                        <input onChange={(e) => setQty(e.target.value)}
                               type="text"
                               onKeyPress={(event) => {
                                   const charCode = event.charCode;
                                   if (charCode < 48 || charCode > 57) {
                                       event.preventDefault();
                                   }
                               }} value={qty}
                               pattern="[0-9]*"
                               placeholder="الكمية"
                               className="input-form my-2 max-width-25"
                        />

                        {/* <span className={'text-orange d-inline-block mx-1 small'}>{totalPrice.toFixed(2)} $</span>*/}
                    </div>
                    <div className="d-flex align-items-center">
                         <span style={{color:"#FFF", fontSize:13}}  className=" my-2  max-width-75 "
                         >{authStore.formatCurrency(totalPrice)} | <span style={{color:'#FF0000'}}>{authStore.formatCurrency(totalPriceSyr, 'SYP')} ل.س</span></span>
                    </div>
                    <input type="text" onChange={(e) => setNote(e.target.value)} multiple={4} placeholder={'ملاحظات'}
                           className={'input-form my-2'}/>


                    {!loading ? (
                        authStore.user === null ? (
                            <Link to="/login" className="btn d-block btn-sm submit-btn">تسجيل الدخول</Link>
                        ) : (
                            <button onClick={onSubmit} className="btn d-block btn-sm submit-btn">شراء</button>
                        )
                    ) : (
                        <button className="btn d-block btn-sm submit-btn">جاري الطلب</button>
                    )}


                </div>
            </div>

        </div>
    )
}