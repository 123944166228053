import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import LoadingComponent from "../Loading/LoadingComponent";
import {useAuthStore} from "../../store/user-store";

export default function BanksList() {

    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [errorImage, setErrorImage] = useState('')
    const [errorAmount, setErrorAmount] = useState('')
    const [banks, setBanks] = useState([])
    const [bank, setBank] = useState({})
    const [amount, setAmount] = useState(0)
    const [image, setImage] = useState(0)
    const [imagePreview, setImagePreview] = useState(null);
    const authStore=useAuthStore();
    const onSubmit = async () => {
        setLoadingSubmit(true)
        if (!image) {
            setErrorImage('يرجى تحديد صورة الإشعار')
            return;
        }

        if (parseFloat(amount) <= 0) {
            setErrorAmount('يرجى إدخال القيمة')
            return;
        }

        const formData = new FormData();
        formData.append('amount', amount);
        formData.append('img', image);
        formData.append('bank_id', bank?.id);
        try {
            var response = await baseApi.post('/charges', formData);
            console.log(response.data);
        } catch (e) {
        }
        setLoadingSubmit(false)
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(file);
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImage(null);
            setImagePreview(null);
        }
    }


    useEffect(() => {
        getBanks()
    }, [])

    const getBanks = async () => {
        setLoading(true)
        try {
            var res = await baseApi('/banks');

            if (res.data?.banks != null) {
                setBanks(res.data?.banks)
            }
            console.log(res.data);
        } catch (e) {

        }
        setLoading(false)
    }

    return <div className={'container'}>
        <div className="row justify-content-center">
            <div className="col-md-4 border border-white border-1 p-3 text-center">
                {loading === false && banks.length === 0 ?
                    <h3 className={'alert alert-danger'}>لا يوجد طرق دفع</h3> : null}
                {loading === true ? <LoadingComponent/> : null}
                <div className="banks-list">

                    {banks.length > 0 ? banks.map((b) => {
                        return (
                            <div key={b.id} onClick={() => setBank(b)}
                                 className={`d-flex flex-column justify-content-center align-items-center bank m-2 ${bank?.id === b.id ? 'active' : ''}`}>
                                <img className={'img-bank'} src={`${b.img}`} alt=""/>
                                <span className={'title-bank'}>{b.name}</span>
                            </div>
                        )
                    }) : null}


                </div>
            </div>
            <div className="col-md-8 p-2">
                {bank?.id == null ? <h3 className={'alert alert-warning'}>يرجى تحديد طريقة دفع</h3> : null}
                {
                    bank?.id != null ? <div>
                            <h3 className={'text-white text-center'}>{bank?.name}</h3>
                            <p className={'text-black text-center bank-info bg-white p-2'}>{bank?.info}
                                <br/>
                                <span>رقم الحساب : </span>
                                <span className={'text-danger'}>{bank?.iban}</span> <i className="fa fa-copy fa-lg mx-2" onClick={()=>authStore.copyText(bank?.iban)}/>

                            </p>


                            <div className="text-center m-auto">
                                <div className="form-group">
                                    <label htmlFor="" className={'text-white'}>القيمة</label>
                                    <input type="text" onChange={(e) => setAmount(e.target.value)}
                                           className="input-form my-2  m-auto" placeholder={'القيمة'} autoComplete={'off'}
                                           value={amount}/>
                                    {errorAmount?.length > 0 ? <span className={'text-danger'}>{errorAmount}</span> : null}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="" className={'text-white'}>صورة الإشعار</label>
                                    <input type="file" onChange={handleImageChange}
                                           className="input-form my-2  m-auto" placeholder={'صورة الإشعار'}
                                           autoComplete={'off'}/>
                                    {errorImage?.length > 0 ? <span className={'text-danger'}>{errorImage}</span> : null}
                                </div>
                                {imagePreview && (
                                    <div className="image-preview mt-3">
                                        <img src={imagePreview} alt="Preview" style={{maxWidth: '100%'}}/>
                                    </div>
                                )}
                            </div>
                            {!loadingSubmit ?
                                <button onClick={onSubmit} className="btn d-block btn-sm submit-btn ">حفظ</button> :
                                <button className="btn d-block btn-sm submit-btn ">جاري المعالجة</button>}
                        </div>
                        : null
                }
            </div>
        </div>
    </div>
}