import axios from 'axios'


// export const baseApi=axios.create({baseURL:'https://app.royal1-card.com/api/v1',withCredentials:true})
// export const baseApi=axios.create({baseURL:'https://app.moscow-card22.com/api/v1',withCredentials:true})
// export const baseApi=axios.create({baseURL:'https://app.smart-store24.com/api/v1',withCredentials:true})
export const baseApi=axios.create({baseURL:'https://app.extra4card.com/api/v1',withCredentials:true})
// export const baseApi=axios.create({baseURL:'http://sail.test/api/v1',withCredentials:true})
// export const baseApi=axios.create({baseURL:'https://app.monaf-gaming.com/api/v1',withCredentials:true})
// export const baseApi=axios.create({baseURL:'https://app.pasha-card.com/api/v1',withCredentials:true})
// export const baseApi=axios.create({baseURL:'https://app.waed-alrawi-card.com/api/v1',withCredentials:true})